body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Merriweather", "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

#tree-canvas {
	min-width: 100%;
	min-height: 100vh;
}

/* For markdown related styling */

p {
	padding-bottom: 15px;
}

i > p {
	padding-bottom: 0px;
}
